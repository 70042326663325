<template>
  <div>
    <Navbar page="Diagnósticos" video="https://www.youtube.com/watch?v=wqwnOr6BGIw" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <router-link v-if="!$store.state.user.cliente" :to="`/${route}/form`" type="button" 
        :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
        class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
        focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
        focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
        rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center
        mb-4
        "
      >
          <span class="inline-block mr-2">Gerar diagnóstico personalizado</span>
      </router-link>

      <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length && !arquivados" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center mb-2 md:ml-4">
        <span class="inline-block">Arquivar selecionadas</span>
      </button>
      
      <div class="grid grid-cols-12 gap-4 mb-3">
        <div class="col-span-12 2xl:col-span-4">
            <label for="titularmenor" class="block text-sm font-medium">
                <input type="checkbox" v-model="arquivados" @change="updateFiltro" class="rounded-sm" id="titularmenor">
                <span class="ml-2">
                    Visualizar arquivados
                </span>
            </label>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                        <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Tema
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Questões
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Respondidas
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Data Finalização
                      </th>
                      <th v-if="!$store.state.user.cliente" scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Responsável
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Nível conformidade
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        Opções
                      </th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr v-for="item in list" :key="item._id">
                      <td class="px-3 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.tema ? item.tema : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.perguntas ? item.perguntas : ''}}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{item.respondidas ? item.respondidas : '0'}}
                          </div>
                        </div>
                      </td>
                      <td class="px-6 py-4">
                        <div class="flex items-center">
                            <div v-if="item.status === 'Pendente'" class="text-sm font-semibold text-pendente">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else-if="item.status === 'Em Andamento'" class="text-sm font-semibold text-andamento">
                              {{ item.status ? item.status: ''}}
                            </div>
                            <div v-else class="text-sm font-semibold text-concluido">
                              {{ item.status ? item.status: ''}}
                            </div>
                        </div>
                      </td>

                      <td class="px-6 py-4">
                        <div v-if="item.dataFinalizada" class="flex items-center">
                          <div class="text-sm text-gray-500">
                            {{ item.dataFinalizada | moment("DD/MM/YYYY")}}
                          </div>
                        </div>
                      </td>

                      <td v-if="!$store.state.user.cliente" class="px-6 py-4 text-sm font-medium">
                        <button v-if="!item.enviada" @click="modalEnviar(item)" class="text-white hover:bg-green-500 bg-green-700 rounded py-1 px-4 mx-1">
                          Enviar
                        </button>
                        <span v-if="item.enviada && !item.fornecedor && !$store.state.user.cliente" class="mr-2 text-gray-500">
                           {{ item.responsavel && item.responsavel.nome ? item.responsavel.nome : 'Consultor' }} 
                        </span>
                        <span v-if="item.enviada && item.fornecedor && !$store.state.user.cliente" class="mr-2 text-gray-500">
                           {{ item.fornecedor && item.fornecedor.nome ? item.fornecedor.nome : 'Consultor' }} 
                        </span>
                      </td>

                      <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                          <div v-if="item.finalizado" class="text-sm text-gray-500">
                            {{item.nivel_conformidade ? `${item.nivel_conformidade}%` : '0%'}}
                          </div>
                        </div>
                      </td>

                      <td class="px-6 py-4 text-sm font-medium">
                          <router-link 
                            type="button"
                            :to="`/${route}/answer/${item._id}`"
                            class="text-white hover:bg-green-600 bg-green-500 rounded py-1 px-4 mx-1">
                            Responder
                          </router-link>
                          <router-link type="button" v-if="!$store.state.user.cliente" :to="`/${route}/form/${item._id}`" class="inline-block text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mt-2 mx-2">
                              Editar
                          </router-link>
                          <router-link type="button" v-if="!$store.state.user.cliente" :to="`/${route}/evidencias/${item._id}`" class="inline-block text-white hover:bg-gray-700 bg-gray-600 rounded py-1 px-4 mt-2 mx-2">
                              Evidências
                          </router-link>
                          <button
                            v-if="!$store.state.user.cliente"
                            @click="duplicar(item._id)" 
                            href="#" 
                            class="text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1">
                            Duplicar
                          </button>
                          <button
                            v-if="!$store.state.user.cliente && !arquivados"
                            @click="remove(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                            Arquivar
                          </button>
                          <button
                            v-else-if="!$store.state.user.cliente && arquivados"
                            @click="ativar(item._id)" 
                            type="button" 
                            class="text-white bg-green-600 hover:bg-green-700 rounded py-1 px-4 mx-1">
                            Ativar
                          </button>
                          <button
                            v-if="arquivados"
                            @click="removeArquivada(item._id)" 
                            type="button" 
                            class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1">
                            Excluir
                          </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
    <modal name="enviarDiagnostico" :adaptive="true" :height="'auto'">
      <div class="px-5 py-5">
        <h2 class="text-xl font-bold mb-5 text-gray-800"> Enviar diagnóstico para responsável </h2>
        <label for="responsavel" class="block text-sm font-medium">
          Tipo Resposável                
        </label>
        <select required v-model="form.tipo_responsavel" name="tipo_responsavel" id="tipo_responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
          <option value="fornecedor">Agente de tratamento</option>
          <option value="cliente">Pessoas na empresa</option>
        </select>
        <label v-if="form.tipo_responsavel" for="responsavel" class="mt-2 block text-sm font-medium">
          {{ form.tipo_responsavel === 'fornecedor' ? 'Agente de tramento do diagnóstico' : 'Responsável pelo diagnóstico' }}                
        </label>
        <select v-if="form.tipo_responsavel === 'fornecedor'" v-model="form.fornecedor" name="responsavel" id="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
          <option :value="null">Selecione um</option>
          <option v-for="fornecedor in fornecedores" :key="fornecedor._id" :value="fornecedor._id">{{ fornecedor.nome }}</option>
        </select>
        <select v-if="form.tipo_responsavel === 'cliente'" v-model="form.responsavel" name="responsavel" id="responsavel" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
          <option :value="null">Selecione um</option>
          <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
        </select>
        <label v-if="form.tipo_responsavel === 'fornecedor'" for="tratamento" class="mt-2 block text-sm font-medium">
          Você pretende diagnosticar o operador ou a empresa ?          
        </label>
        <select v-if="form.tipo_responsavel === 'fornecedor'" v-model="form.tratamento" name="tratamento" id="tratamento" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
          <option value="Empresa">Empresa</option>
          <option value="Operador">Operador</option>
        </select>
        <button @click="enviarParaResponsavel(form)" class="text-white hover:bg-green-700 bg-green-600 rounded py-1 px-4 my-4">
            Enviar diagnóstico
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'diagnosticos',
      form: {
        responsavel: null,
        fornecedor: null,
        tratamento: '',
      },
      arquivados: false,
      pessoas: [],
      fornecedores: [],
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      total: 0,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { arquivados: this.arquivados, skip: this.query.skip, limit: this.query.limit  });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async modalEnviar(item){
      this.form = item;
      this.$modal.show('enviarDiagnostico');
    },

    async enviarParaResponsavel(diagnostico) {
      this.$modal.hide('enviarDiagnostico');
      if(!diagnostico.responsavel && !diagnostico.fornecedor){
          this.$confirm({
          title: 'Consultor responsável',
          message: `Deseja marcar o consultor como responsável ?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if(!confirm) return;
            await this.$http.put(`/v1/${this.route}/`,{ _id: diagnostico._id, enviada: true, responsavel: null, respondido: true });
            this.start();
            this.grafico();
          }
        })
        return
      }

      this.$confirm({
        title: 'Enviar diagnostico',
        message: `Deseja enviar o diagnostico para o responsável ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.put(`/v1/${this.route}/`,{ _id: diagnostico._id, enviada: true, responsavel: diagnostico.responsavel, fornecedor: diagnostico.fornecedor, tratamento: diagnostico.tratamento });
          this.start();
        }
      })
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Arquivadas com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async removeArquivada (id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja excluir esse diagnóstico arquivado?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/remover/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },
    
    async remove(id) {
      this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja arquivar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`/v1/${this.route}/${id}`);
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    async ativar(id) {
    this.$confirm({
          title: 'Confirmação',
          message: `Tem certeza que deseja ativar esse diagnóstico?`,
          button: {
              no: 'Não',
              yes: 'Sim',
          },
          callback: async confirm => {
            if (confirm) {
              await this.$http.put(`/v1/${this.route}/`, { _id: id, ativo: true });
              this.$vToastify.success("Removido com sucesso!");
              this.page = 1;
              this.start();
            }
          }
      });
    },

    async duplicar(id) {
      await this.$http.post(`/v1/${this.route}/duplicar/`,{ _id: id });
      this.$vToastify.success("Duplicado com sucesso!");
      this.start();
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, arquivados: this.arquivados };
      this.$router.push({ path: `/${this.route}`, query: this.query });
    },

    async selecionarTodos(){
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, arquivados: this.arquivados });
      this.selecionados = listReq.data.data;
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.arquivados = this.arquivados;

      this.$router.push({ path: `/${this.route}`, query: this.query });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.arquivados) this.arquivados = this.$route.query.arquivados === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();

    const listPessoas = await this.$http.post(`/v1/pessoas/list`, { all: true});
    this.pessoas = listPessoas.data.data;

    const listFornecedores = await this.$http.post(`/v1/fornecedores/list`, { all: true});
    this.fornecedores = listFornecedores.data.data; 
  },
}
</script>